






















































































import {Vue, Component} from 'vue-property-decorator';
import TrainingRecordApi from '@/api/training-record'

@Component({})
export default class MyTraining extends Vue {
  private api = new TrainingRecordApi()

  private trainType = 101
  private trainList = [
    {title: '有氧训练', value: 101},
    {title: '力量训练', value: 102}
  ]

  private trainingList = new Array<any>()
  private queryDate = ''
  private minDate = new Date(2020, 0, 1)
  private maxDate = new Date()
  private currentDate = new Date()
  private showPicker = false

  private async activated() {
    const nowDate = new Date()
    const month = (nowDate.getMonth() + 1).toString().padStart(2, '0')
    const day = (nowDate.getDate()).toString().padStart(2, '0')
    this.queryDate = `${nowDate.getFullYear()}-${month}-${day}`

    await this.getMyTrainingList()
  }

  // TODO 训练类型选择
  private async handleClickTrainType(type: number) {
    this.trainType = type
    await this.getMyTrainingList()
  }

  // TODO 时间
  private async onConfirm(time: any) {
    this.currentDate = time;
    const nowDate = new Date(time)
    const month = (nowDate.getMonth() + 1).toString().padStart(2, '0')
    const day = (nowDate.getDate()).toString().padStart(2, '0')
    this.queryDate = `${nowDate.getFullYear()}-${month}-${day}`
    this.showPicker = false;

    await this.getMyTrainingList()
  }

  // TODO 获取训练记录
  private async getMyTrainingList() {
    this.trainingList = []
    const result = await this.api.getMyList(this.trainType, this.queryDate)
    if (result) {
      this.trainingList = result
    }
  }
}
