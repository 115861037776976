import request from '@/utils/request'

export default class TrainingRecordApi {
    public getMyList(ttype: any, queryDate: any): Promise<any> {
        return new Promise((resolve, reject) => {
            request({
                url: `/keep-fit/training-record/list`,
                method: 'get',
                params: {
                    trainingType: ttype,
                    queryDate: queryDate
                }
            }).then((res) => resolve(res.data)).catch((err) => reject(err));
        })
    }
}
